import Vue from 'vue';
import axios from 'axios';
import Component from 'vue-class-component';

@Component
export default class ImagesMixin extends Vue {
  getThumbnail(url: string): string {
    return this.getResizedImageUrl(url, '200x200');
  }

  getOriginalImageUrl(url: string): string {
    return this.getResizedImageUrl(url, 'original');
  }

  getResizedImageUrl(url: string, size: string): string {
    const backendUrl: string = (axios.defaults.baseURL as string);

    return `${ backendUrl }/cache/${ size }/${ url.replace(backendUrl, '') }`;
  }
}
